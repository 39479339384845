import React, {useEffect, useRef} from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Banner from "../../components/banner"
import CustomLink from "../../components/link"
import Irstrat1 from "../../../static/img/irstrat/Irstrat1.png"
import Irstrat2 from "../../../static/img/irstrat/Irstrat2.png"
import irstrat3 from "../../../static/img/irstrat/irstrat3.jpg"
import aumento_liquidez from "../../../static/img/irstrat/aumento_liquidez.png"
import emision_deuda from "../../../static/img/irstrat/emision_deuda.png"
import incursion_mercados from "../../../static/img/irstrat/incursion_mercados.png"
import ipo from "../../../static/img/irstrat/ipo.png"
import renta_fija from "../../../static/img/irstrat/renta_fija.png"

const Contenido = (props) => {
    const isMountedComponent = useRef(true)
    useEffect(() => {
        if (isMountedComponent.current) {
            window.jquery_scripts()
        }
        return () => {
            isMountedComponent.current = false
        }
    })
    return (
        <Layout location={props.location}>
            <SEO title="Servicios"/>
            <Banner title="Servicios"/>
            <section className="propuesta mt-5">
                <div className="container">
                    <div className="section_title black_c">
                        <h2>Soluciones estándar</h2>
                        <span className="sec_border"></span>
                        <p className="f_16">Denominamos como “Soluciones Estándar” a un empaquetamiento de servicios
                            enfocado a reforzar los programas de relaciones con inversionistas de las emisoras mexicanas
                            de deuda y capitales que ya tengan instrumentos cotizando en los mercados públicos
                            (acciones, CBFIs, CBFs, CKDs y eurobonos registrados). IRStrat cuenta con tres paquetes de
                            “Soluciones Estándar”:</p>
                    </div>
                    <div className="row mb-5">
                        <div className="col-lg-4">
                            <div className="d-flex align-items-start">
                                <i className="fa fa-check-square-o mt-1 mr-2"></i>
                                <h5 className="mb-2">IRStrat I: enfocado a “imagen e identidad”</h5>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="d-flex align-items-start">
                                <i className="fa fa-check-square-o mt-1 mr-2"></i>
                                <h5 className="mb-2">IRStrat II: enfocado a “mensaje estratégico”</h5>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="d-flex align-items-start">
                                <i className="fa fa-check-square-o mt-1 mr-2"></i>
                                <h5 className="mb-2">IRStrat III: enfocado a “inteligencia y lobbying”</h5>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-md-6">
                            <i>Consideramos que estas tres opciones de soluciones cubren a profundidad los aspectos más
                                importantes de cualquier programa de RI, y su contratación conjunta constituye el mejor
                                programa de soluciones para los emisores mexicanos.</i>
                        </div>
                        <div className="col-md-6">
                            <i>Las denominamos estándar porque consideramos que para un óptimo posicionamiento y
                                percepción, cualquier emisor debe cumplir con estas tres piedras angulares de las
                                relaciones con inversionistas. </i>
                        </div>
                    </div>
                </div>
            </section>
            <section className="practice_area servicios mt-5 pt-4">
                <div className="container">
                    <ul className="list_style gallery-filter">
                        <li className="active" data-filter="*">Todos</li>
                        <li data-filter=".estandar">Soluciones estándar</li>
                        <li data-filter=".medidad">Soluciones a la medida</li>
                        {/*<li data-filter=".iredge">IR Edge</li>*/}
                    </ul>
                    <div id="gallery" className="row gallery_inner practice_inner">
                        <div className="col-md-4 gallery-item estandar">
                            <div className="practice_item gallery_post">
                                <div className="practice_image">
                                    <img src={Irstrat1} alt={" "}/>
                                    <CustomLink className="read_btn" to="/servicios/irstrat_1">
                                        Leer más
                                    </CustomLink>
                                </div>
                                <CustomLink to="/servicios/irstrat_1">
                                    <h5>IRStrat I</h5>
                                </CustomLink>
                            </div>
                        </div>
                        <div className="col-md-4 gallery-item estandar">
                            <div className="practice_item gallery_post">
                                <div className="practice_image">
                                    <img src={Irstrat2} alt={" "}/>
                                    <CustomLink className="read_btn" to="/servicios/irstrat_2">
                                        Leer más
                                    </CustomLink>
                                </div>
                                <CustomLink to="/servicios/irstrat_2">
                                    <h5>IRStrat II</h5>
                                </CustomLink>
                            </div>
                        </div>
                        <div className="col-md-4 gallery-item estandar">
                            <div className="practice_item gallery_post">
                                <div className="practice_image">
                                    <img src={irstrat3} alt={" "}/>
                                    <CustomLink className="read_btn" to="/servicios/irstrat_3">
                                        Leer más
                                    </CustomLink>
                                </div>
                                <CustomLink to="/servicios/irstrat_3">
                                    <h5>IRStrat III</h5>
                                </CustomLink>
                            </div>
                        </div>

                        <div className="col-md-4 gallery-item medidad">
                            <div className="practice_item gallery_post">
                                <div className="practice_image">
                                    <img src={aumento_liquidez} alt={" "}/>
                                    <CustomLink className="read_btn" to="/servicios/aumento_liquidez">
                                        Leer más
                                    </CustomLink>
                                </div>
                                <CustomLink to="/servicios/aumento_liquidez">
                                    <h5>Aumento de liquidez</h5>
                                </CustomLink>
                            </div>
                        </div>
                        <div className="col-md-4 gallery-item medidad">
                            <div className="practice_item gallery_post">
                                <div className="practice_image">
                                    <img src={emision_deuda} alt={" "}/>
                                    <CustomLink className="read_btn" to="/servicios/emision_deuda">
                                        Leer más
                                    </CustomLink>
                                </div>
                                <CustomLink to="/servicios/emision_deuda"><h5>Emisión de Deuda</h5></CustomLink>
                            </div>
                        </div>
                        <div className="col-md-4 gallery-item medidad">
                            <div className="practice_item gallery_post">
                                <div className="practice_image">
                                    <img src={incursion_mercados} alt={" "}/>
                                    <CustomLink className="read_btn" to="/servicios/programa_incursion">
                                        Leer más</CustomLink>
                                </div>
                                <CustomLink to="/servicios/programa_incursion">
                                    <h5>Incursión a los mercados</h5>
                                </CustomLink>
                            </div>
                        </div>

                        <div className="col-md-4 gallery-item medidad">
                            <div className="practice_item gallery_post">
                                <div className="practice_image">
                                    <img src={ipo} alt={" "}/>
                                    <CustomLink className="read_btn" to="/servicios/programa_ipo_medida">
                                        Leer más
                                    </CustomLink>
                                </div>

                                <CustomLink to="/servicios/programa_ipo_medida"><h5>IPO</h5></CustomLink>
                            </div>
                        </div>
                        <div className="col-md-4 gallery-item medidad">
                            <div className="practice_item gallery_post">
                                <div className="practice_image">
                                    <img src={renta_fija} alt={" "}/>
                                    <CustomLink to="/servicios/programa_medida_renta" className="read_btn">Leer
                                        más</CustomLink>
                                </div>
                                <CustomLink to="/servicios/programa_medida_renta"><h5>Renta Fija</h5></CustomLink>
                            </div>
                        </div>
                        {/*<div className="col-md-4 gallery-item iredge">*/}
                        {/*<div className="practice_item gallery_post">*/}
                        {/*<div className="practice_image">*/}
                        {/*<img src={`../../img/irstrat/anuales.png`} alt={" "}/>*/}
                        {/*<CustomLink to="/servicios/anual_sustentabilidad" className="read_btn">Leer*/}
                        {/*más</CustomLink>*/}
                        {/*</div>*/}
                        {/*<CustomLink to="/servicios/anual_sustentabilidad"><h5>Informe Anual y*/}
                        {/*Sustentabilidad</h5></CustomLink>*/}
                        {/*</div>*/}
                        {/*</div>*/}


                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default Contenido
